import {
  mdiAlertOutline,
  mdiBattery,
  mdiBattery20,
  mdiBattery40,
  mdiBattery80,
  mdiBatteryOutline,
  mdiCakeVariant,
  mdiCalendarMonth,
  mdiCellphoneMarker,
  mdiClockOutline,
  mdiCow,
  mdiDna,
  mdiGenderFemale,
  mdiGenderMale,
  mdiLightbulbGroupOutline,
  mdiMap,
  mdiMapMarkerPath,
  mdiPlusThick,
  mdiShakerOutline,
  mdiShoePrint,
  mdiSilverwareForkKnife,
  mdiUmbrellaBeach,
  mdiWaterOutline,
  mdiWaves,
  mdiWeight,
} from '@mdi/js'
import PlotIconPrimary from 'assets/svgs/plot-primary.svg'
import PlotIcon from 'assets/svgs/plot.svg'
import GrowPhaseIcon from 'assets/svgs/grow_phase.svg'
import GrowPhaseIconPrimary from 'assets/svgs/grow-phase-primary.svg'
import PregnantStatus from 'assets/svgs/pregnancy-status.svg'
import PlotAlertWhite from 'assets/svgs/plot_alert_white.svg'
import PregnantStatusWhite from 'assets/svgs/pregnancy-status-white.svg'
import ExternalAlertWhite from 'assets/svgs/external_alert.svg'

export default function selectIcon(type, color) {
  let avatar

  switch (type) {
    case 'device':
      avatar = mdiCellphoneMarker
      break
    case 'battery-full':
      avatar = mdiBattery
      break
    case 'battery-80':
      avatar = mdiBattery80
      break
    case 'battery-40':
      avatar = mdiBattery40
      break
    case 'battery-20':
      avatar = mdiBattery20
      break
    case 'battery-none':
      avatar = mdiBatteryOutline
      break
    case 'lot':
      avatar = mdiMap
      break
    case 'entry_plot':
      avatar = mdiCalendarMonth
      break
    case 'last_activity':
      avatar = mdiClockOutline
      break
    case 'breed':
      avatar = mdiDna
      break
    case 'age':
      avatar = mdiCakeVariant
      break
    case 'steps':
      avatar = mdiShoePrint
      break
    case 'meters':
      avatar = mdiMapMarkerPath //mdiGesture
      break
    case 'sex_male':
      avatar = mdiGenderMale
      break
    case 'sex_female':
      avatar = mdiGenderFemale
      break
    case 'is_pregnant':
      avatar = PregnantStatus
      break
    case 'is_sick':
      avatar = mdiPlusThick
      break
    case 'plot':
      if (color === 'primary') {
        avatar = PlotIconPrimary
      } else {
        avatar = PlotIcon
      }
      break
    case 'grow_phase':
      if (color === 'primary') {
        avatar = GrowPhaseIconPrimary
      } else {
        avatar = GrowPhaseIcon
      }
      break
    case 'animal':
      avatar = mdiCow
      break
    case 'bulb':
      avatar = mdiLightbulbGroupOutline
      break
    case 'lake-dam-weir':
      avatar = mdiWaves
      break
    case 'feeder':
      avatar = mdiSilverwareForkKnife
      break
    case 'salt-trough':
      avatar = mdiShakerOutline
      break
    case 'trough':
      avatar = mdiWaterOutline
      break
    case 'shelter-shadow':
      avatar = mdiUmbrellaBeach
      break
    case 'plot_alert':
    case 'internal_alert':
      avatar = PlotAlertWhite
      break
    case 'possible_birth':
      avatar = PregnantStatusWhite
      break
    case 'zero_steps_alert':
      avatar = mdiShoePrint
      break
    case 'external_alert':
      avatar = ExternalAlertWhite
      break
    case 'possible_illness':
      avatar = mdiPlusThick
      break
    case 'weight':
      avatar = mdiWeight
      break
    case 'unread_alerts':
      avatar = mdiAlertOutline
      break
    default:
      break
  }
  return avatar
}
