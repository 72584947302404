/**
 * Para gerar algo em negrito basta acrescentar ** em volta da frase **
 * lembre-se o nome informado entre {} na mensagem deve ser a mesma chave passada dentro dos 'items'
 * exemplo: {
    howMany: {
      one: { gender: { male: 'vazio', female: 'vazia' } },
      many: { gender: { male: 'vazios', female: 'vazias' } }
    }
  }
 */
export const pt_BR = {
  'date_entrance_farm_weight': 'data de pesagem de entrada',
  'register_weighing': 'registrar pesagem',
  'illness_alert': 'alerta de doença',
  'childbirth_alert': 'alerta de parto',
  'occurrence_date': 'ocorrido há',
  'alert_type': 'tipo de alerta',
  'birth_windows': 'janelas de parto',
  'final_date': 'data final',
  'initial_date': 'data inicial',
  'registration_date': 'data de registro',
  'pregnancy': 'prenhez',
  'birth_window': {
    howMany: {
      one: 'janela de parição',
      many: 'janelas de parição',
    },
  },
  'delete_birth_window': 'remover janela de parição',
  'edit_birth_window': 'editar janela de parição',
  'expected_period': 'período esperado para o parto',
  'status_is_pregnant': 'animal prenho',
  'register_pregnancy': 'registrar janela de parição',
  'permanency_time': 'tempo de permanência',
  'entrance_date': 'data de entrada',
  'departure_date': 'data de saída',
  'using_structures': 'Utilização de estruturas',
  'structure_area': 'área da estrutura',
  'datagrid_records_info': 'registro {from} à {to} de {total}',
  'name_earring': 'nome ou brinco',
  'status_no_movement': 'Sem movimentação recente',
  'device_process': 'dispositivo em processo de movimentação',
  'status_error': 'erro',
  'status_success': 'sucesso',
  'status_pending': 'em processamento',
  'archive': 'arquivo',
  'archives': 'Arquivos',
  'repository': 'Repositório',
  'precision': 'precisão',
  'accuracy_for_recording': 'precisão para gravação de pontos de monitoramento',
  'zero_steps_alert_interval': 'Tolerância para o alerta de ausência de passos',
  'edit_information': 'editar informação',
  'zero_steps_alert': 'ausência de passos',
  'response_sent_successfully': 'resposta enviado com sucesso',
  'record_information': 'registrar informações',
  'clear_filter': 'limpar filtro',
  'has_been_removed': 'este animal foi removido do sistema!',
  'death': 'morte',
  'theft': 'roubo',
  'cadastral_error': 'erro de cadastro',
  'only_monitored': 'apenas Monitorados',
  'displacement_word': 'Deslocamento',
  'not_found_default_title': 'Página não encontrada',
  'go_back': 'voltar',
  'not_found_default_message':
    'Percebemos que você se perdeu, não se preocupe, nós o ajudaremos a encontrar o caminho correto',
  'position_selected': 'posição selecionada',
  'move_batch_to_lot': 'mover lote "{piquete}" para o piquete',
  'category_of_animals': 'categoria dos animais',
  'link': 'vincular',
  'unlink': 'desvincular',
  'no_movement': 'sem movimentação',
  'controls': 'controles',
  'batch_exchange': '**Troca do lote realizada.** O lote foi alocado no **{item}**, em: {time}',
  'calf': 'bezerro/a',
  'yearling': 'novilho/a',
  'go_to_animal': 'ir para o animal',
  'change_of': 'troca de {item}',
  'device': 'dispositivo',
  'notifications': 'notificações',
  'external_alert_profile': 'alerta de fuga externa (limites da fazenda)',
  'internal_alert_profile': 'alerta de fuga interna (piquete)',
  'plot_alert_profile': 'alerta movimentação de lotes (piquete)',
  'alert_activated_message': '{item} ativado com sucesso',
  'alert_deactivated_message': '{item} desativado com sucesso',
  'external_alert': 'alerta de fuga externa (limites da fazenda)',
  'external_alert_accuracy': 'Precisão dos pontos para fuga externa (limites da fazenda)',
  'internal_alert': 'alerta de fuga interna (piquete)',
  'internal_alert_accuracy': 'Precisão dos pontos para fuga interna (piquete)',
  'plot_alert': 'alerta movimentação de lotes (piquete)',
  'plot_alert_accuracy': 'Precisão dos pontos para Alerta de Movimentação de lote (piquete)',
  'alerts': 'alertas',
  'average_weight': 'peso médio',
  'total_weight': 'peso total',
  'user_profile': 'perfil do usuário',
  'category': 'categoria',
  'categories': 'categorias',
  'filters': 'filtros',
  'maleCalf': 'bezerro',
  'femaleCalf': 'bezerra',
  'maleYearling': 'novilho',
  'femaleYearling': 'novilha',
  'steer': 'boi',
  'cow': 'vaca',
  'bull': 'touro',
  'totalArea': 'área pastejo ({unit})',
  'totalCattles': 'rebanho total',
  'devicedCattles': 'animais monitorados',
  'percentageUsageArea': 'taxa de lotação (UA/{unit})',
  'details': 'detalhes',
  'battery': 'bateria',
  'batt_level': 'nível de bateria',
  'results_found': '{item} resultados encontrados',
  'heifers': 'novilhos',
  'calves': 'bezerros',
  'day': 'dia',
  'days': 'dias',
  'maximum_age': 'idade máxima {item} {time}',
  'growth_stage': 'fase de crescimento',
  'monitoring_behavior': 'monitoramento e comportamento',
  'packages': 'pacotes de dados',
  'age': 'idade',
  'date_entrance_farm': 'data de entrada na fazenda',
  'date_last_weigh_in': 'data da última pesagem',
  'weight_last_weigh_in': 'peso na última pesagem',
  'entrance_weight': 'peso de entrada',
  'bnd_registration_date': 'data de registro BND',
  'sisbov_number': 'número SISBOV',
  'male_type': 'tipo de macho',
  'interval': 'intervalo',
  'minutes': '{prefix} minutos {sufix}',
  'hours': '{prefix} horas {sufix}',
  'packet_send_frequency': 'frequência de envio de pacotes',
  'packet_send_frequency_message':
    'Quanto maior o intervalo, menor o consumo de bateria. O menor consumo de bateria aumenta a vida útil do dispositivo.',
  'lot_entry': 'entrada no piquete',
  'current_lot': 'piquete atual',
  'loading': 'carregando',
  'updated': 'atualizado em',
  'no-data': 'sem dados',
  'exporting': 'exportando',
  'plot_area_total': 'área total do piquete: {area} {measure}',
  'polygon_area_total': 'área total do polígono: {area} {measure}',
  'map': 'mapa',
  'property_boundaries': 'limites da propriedade',
  'property_boundary': 'limite da propriedade',
  'movimentation': 'movimentação',
  'steps': 'passos',
  'monitoring': 'monitoramento',
  'neutral_zone': 'zona neutra (não alertar)',
  'kg': 'quilograma (kg)',
  'mi': 'milhas (mi)',
  'km': 'kilometros (km)',
  'm': 'metros (m)',
  'ha': 'hectares (ha)',
  'm2': 'metros quadrados (m²)',
  'weight': 'peso',
  'length': 'comprimento',
  'measurement_units': 'unidades de medida',
  'lot_type': 'tipo de piquete',
  'animal-handling-area': 'Área de Manejo de Animais',
  'grazing-area': 'Área de Pastejo',
  'preservation-area-app': 'Área de Preservação - APP',
  'nursery': 'Enfermaria',
  'shed-warehouse': 'Galpão / Armazém',
  'lake-weir-dam': 'Lago / Açude / Barragem',
  'corral': 'Mangueira / Curral',
  'feeding-lane': 'Pista de Alimentação',
  'forestry-production': 'Produção Florestal',
  'headquarters-residence': 'Sede / Residência',
  'is_on_line': 'online',
  'gateway': {
    howMany: {
      one: 'antena',
      many: 'antenas',
    },
  },
  'structure_help_text':
    'Para selecionar a localização, clique (duplo clique) sobre a localização da estrutura no mapa abaixo.',
  'shelter': 'abrigo / Sombra',
  'trough': 'bebedouro',
  'feeder': 'comedouro',
  'salt-trough': 'cocho de Sal',
  'structure': 'estrutura',
  'structure_many': 'estruturas',
  'structure_type': 'tipo de estrutura',
  'gateway_type': 'tipo de antena',
  'no_matching_results_found': 'nenhum resultado correspondente encontrado',
  'device_moved': 'Device movimentado com sucesso',
  'application_target': 'application de destino',
  'application_name': 'nome da application',
  'moreSixHour': 'sem atualização (+6h)',
  'moreTwelveHours': 'sem atualização (+12h)',
  'move_to': 'mover para',
  'move_device_to': 'mover device {item} para',
  'access_device': 'Acessar dispositivo',
  'linked': {
    gender: {
      male: '{item} vinculados',
      female: '{item} vinculadas',
    },
  },
  'unlinked': '{item} sem vinculo',
  'linked_to': 'vinculada a',
  'link_device': 'Vincular dispositivo {item}',
  'unlink_device': 'Desvincular dispositivo {item}',
  'logout_message': 'Deslogando. Você será redirecionado.',
  'lat': 'latitude',
  'lng': 'longitude',
  'alt': 'altitude',
  'batt': 'bateria',
  'application': 'aplicação',
  'devices': {
    howMany: {
      one: 'dispositivo',
      many: 'dispositivos',
    },
  },
  'generic_error': 'Aconteceu um erro na sua solicitação, tente novamente mais tarde.',
  'pt-BR': 'português (PT-BR)',
  'en-US': 'inglês (EN-US)',
  'theme': 'Aparência do sistema',
  'dark': 'escuro',
  'light': 'branco',
  'language': 'idioma',
  'passwords_and_security': 'senhas e segurança',
  'home': 'home',
  'new_password': 'nova senha',
  'current_password': 'senha atual',
  'password_confirmation': 'confirmação da senha',
  'passwords': {
    howMany: {
      one: 'senha',
      many: 'senhas',
    },
  },
  'personal_information': 'informações pessoais',
  'register_item': 'Cadastro de {item}',
  'movement_item': 'movimentação de {item}',
  'color': 'cor',
  'area': 'área',
  'lots': {
    howMany: {
      one: 'piquete',
      many: 'piquetes',
    },
  },
  'search': 'Pesquisar',
  'pessoa_fisica': 'Pessoa Física',
  'pessoa_juridica': 'Pessoa Jurífica',
  'access_farm': 'Acessar Fazenda',
  'password_recovery': 'Recuperação de senha',
  'password_recovery_text':
    'Você tem certeza que deseja solicitar o cadastramento de uma nova senha?',
  'password_recovery_success':
    'Solicitação enviada com sucesso. Solicite que o usuário verifique a sua caixa de e-mail e conclua o cadastro criando sua senha de acesso.',
  'password_recovery_text_2': 'Tem certeza que deseja solicitar a recuperação de senha?',
  'password_recovery_success_2':
    'Você receberá um e-mail com orientações para recuperar a sua senha.',
  'all_item': {
    gender: {
      male: 'todos {item}',
      female: 'todas {item}',
    },
  },
  'item_selected': {
    gender: {
      male: '{item} selecionados',
      female: '{item} selecionadas',
    },
  },
  'no_access': 'sem acesso',
  'repeat_item': 'repetir {item}',
  'city': 'cidade',
  'state': 'estado',
  'country': 'país',
  'localization': 'Localização {de} {item}',
  'dashboard': 'dashboard',
  'account': {
    howMany: {
      one: 'conta {item}',
      many: 'contas {item}',
    },
  },
  'date': 'data',
  'birth_date': 'data de nascimento',
  'root': 'root',
  'farm': 'fazenda',
  'property': {
    howMany: {
      one: 'propriedade',
      many: 'propriedades',
    },
  },
  'herd': 'rebanho',
  'breed': 'raça',
  'animal': {
    howMany: {
      one: 'animal {item}',
      many: 'animais {item}',
    },
  },
  'batch': {
    howMany: {
      one: 'lote {item}',
      many: 'lotes {item}',
    },
  },
  'role': {
    howMany: {
      one: 'papel {item}',
      many: 'papéis {item}',
    },
  },
  'inform': {
    gender: {
      male: 'informe o {item}',
      female: 'informe a {item}',
    },
  },
  'select': {
    gender: {
      male: 'selecione o {item}',
      female: 'selecione a {item}',
    },
  },
  'name': {
    howMany: {
      one: 'nome {de} {item}',
      many: 'nomes {de} {item}',
    },
  },
  'cancel': 'cancelar',
  'confirm': 'confirmar',
  'context': 'contexto',
  'sign_out': 'sair',
  'plan': {
    howMany: {
      one: 'plano {item}',
      many: 'planos {item}',
    },
  },
  'module': {
    howMany: {
      one: 'modulo',
      many: 'modulos',
    },
  },
  'type': 'tipo',
  'update': 'editar {item}',
  'remove': 'remover {item}',
  'delete': 'excluir {item}',
  'register': 'registrar {item}',
  'create': 'cadastrar {item}',
  'new': 'novo {item}',
  'new_female': 'nova {item}',
  'functionality': {
    howMany: {
      one: 'funcionalidade',
      many: 'funcionalidades',
    },
  },
  'to_view': 'visualizar',
  'profile': 'perfil',
  'setting': {
    howMany: {
      one: 'configuração {de} {item}',
      many: 'configurações {de} {item}',
    },
  },
  'gender': 'sexo',
  'male': 'macho',
  'female': 'fêmea',
  'management': 'gerenciamento {de} {item}',
  'description': 'descrição',
  'success_message': 'Sua solicitação foi realizada.',
  'success_register': {
    gender: {
      male: '{item} registrado com sucesso',
      female: '{item} registrada com sucesso',
    },
  },
  'success_remove': {
    gender: {
      male: '{item} removido com sucesso',
      female: '{item} removida com sucesso',
    },
  },
  'success_discard': {
    gender: {
      male: '{item} descartado com sucesso',
      female: '{item} descartada com sucesso',
    },
  },
  'confirm_remove': {
    gender: {
      male: 'Você tem certeza que deseja remover o {item} **"{name}"**?',
      female: 'Você tem certeza que deseja remover a {item} **"{name}"**?',
    },
  },
  'confirm_remove_dont_name': {
    gender: {
      male: 'Você tem certeza que deseja remover o {item}?',
      female: 'Você tem certeza que deseja remover a {item}?',
    },
  },
  'confirm_unlink': {
    gender: {
      male: 'Você tem certeza que deseja desvincular o {item} **"{name}"**?',
      female: 'Você tem certeza que deseja desvincular a {item} **"{name}"**?',
    },
  },
  'confirm_discard': {
    gender: {
      male: 'Você tem certeza que deseja descartar o {item} **"{earring} - {name}"**?',
      female: 'Você tem certeza que deseja descartar a {item} **"{earring} - {name}"**?',
    },
  },
  'copyright': 'Todos os direitos reservados.',
  'user': {
    howMany: {
      one: 'usuário {item}',
      many: 'usuários {item}',
    },
  },
  'email': 'e-mail',
  'goal': 'finalidade',
  'earring': 'brinco',
  'phone': 'telefone',
  'role_id': 'papel',
  'localization_help_text':
    'Informe a latitude e longitude da sua propriedade, ou clique (duplo clique) sobre a localização da propriedade no mapa abaixo.',
  'email_is_exists':
    'não é possível cadastrar este usuário, o e-mail ja está sendo utilizado por outro usuário',
  'phone_is_exists':
    'não é possível cadastrar este usuário, o telefone ja está sendo utilizado por outro usuário',
  'registration_is_exists':
    'não é possível cadastrar esta conta, o CPF/CNPJ ja está sendo utilizado',
  'email_validation_failed':
    'há algum problema com o email informado, por favor verifique e tente novamente.',
  'confirmed_validation_failed': 'Email de confirmação não confere.',
  'minLength_validation_failed':
    'houve um problema no valor informado no campo: **{item}** - {rule}',
  'minLength': 'minimo de caracteres: {item}',
  'maxLength': 'máximo de caracteres: {item}',
  'confirmed': 'confirmed',
  'last_access': 'último acesso',
  'last_activity': 'última atividade',
  'E_INVALID_AUTH_PASSWORD': 'email ou senha inválido',
  'required': 'esse campo é obrigatório',
  'email_invalid': 'O valor não é um E-mail válido!',
  'email_required': 'Por favor, insira seu e-mail!',
  'filterTitle': 'Menu de filtro',
  'filterConfirm': 'OK',
  'filterReset': 'Redefinir',
  'filterEmptyText': 'Sem filtros',
  'filterCheckall': 'Selecione todos os itens',
  'filterSearchPlaceholder': 'Pesquisar nos filtros',
  'emptyText': 'Sem dados',
  'selectAll': 'Selecione a página atual',
  'selectInvert': 'Inverter a página atual',
  'selectNone': 'Limpar todos os dados',
  'selectionAll': 'Selecionar todos os dados',
  'sortTitle': 'Ordenar',
  'expand': 'Expandir linha',
  'collapse': 'Recolher linha',
  'triggerDesc': 'Clique para ordenação decrescente',
  'triggerAsc': 'Clique para ordenação crescente',
  'cancelSort': 'Clique para cancelar a ordenação',
  'responsible': 'Responsável',
  'name_is_exists':
    'já existe um papél no contexto {context} com **nome idêntico**. Altere o nome e repita a operação.',
  'date_created': 'criado em',
  'unique_validation_failure':
    'já existe um outro animal cadastrado com **{item}** informado. Verifique novamente e/ou altere para concluir o cadastro.',
  'moduleAccess': 'Módulos de acesso',
  'dateFormat': 'DD/MM/YYYY',
  'datetimeFormat': 'DD/MM/YYYY HH:mm',
  'lot_created': '{date} - Criação do **{plot}**',
  'lot_moved_between': '{date} - Movimentação do piquete **{origin}** para **{destine}**',
  'lot_moved_in': '{date} - Entrada do **{plot}** em **{destine}**',
  'lot_moved_out': '{date} - Saida do **{plot}**',
  'error': '**{item}** - {rule}',
  'see_details': 'Ver detalhes',
  'device_name': 'Dispositivo {name}',
  'version': 'Versão',
  'copy_data': 'Copiar Dados',
  'check_all': 'Marcar todos',
  'batch_behavior': 'Comportamento do lote',
  'movement_history': 'Histórico de movimentação',
  'monitored': {
    howMany: {
      one: 'Monitorado',
      many: 'Monitorados',
    },
  },
  'batch_entry': 'entrada no lote',
  'displacement': 'Deslocamento acumulado do lote (24 Horas)',
  'displacement_hour': 'Deslocamento hora a hora do lote',
  'vinculed_at': 'vinculado em:',
  'status': 'Status',
  'data_reception': 'Recepção de dados',
  'last_update': 'ultima atualização',
  'link_history': 'Histórico de vinculo',
  'device_linked': '{date} - Vinculado ao animal **{cattle}**',
  'device_unlinked': '{date} - Desvinculado do animal **{cattle}**',
  'device_entry': '{date} - Entrada na fazenda **{farm}**',
  'device_exit': '{date} - Saida da fazenda **{farm}**',
  'device_between': '{date} - Movido da fazenda **{oldFarm}** para **{farm}**',
}
